import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect } from 'react'
import { useCart } from 'src/sdk/cart/useCart'
import axios from 'axios'
import { RenderClient } from 'src/components/RenderClient'

function Page() {
  const { setCart: setLocaleCart } = useCart()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Get a new orderForm, and update the local cart
      axios.get(`/api/checkout/getCartInformation?orderFormId=`).then((res) => {
        setLocaleCart({
          id: res.data.orderFormId,
          items: [],
        })
        // The replace function is used here to overwrite the last browser-history record
        // and avoid the cart is cleared multiple times if the user goes back in the browser-history
        window.location.replace('/')
      })
    }
  }, [])

  return (
    <RenderClient>
      <GatsbySeo />

      <div>loading...</div>
    </RenderClient>
  )
}

export default Page
